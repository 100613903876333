import * as React from 'react';
import styled, { ITheme } from 'styled-components';

const CheckVector = () => (
    <svg width={14} height={10} viewBox="0 0 14 10" fill="none">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.7071 0.29289C14.0976 0.68341 14.0976 1.31658 13.7071 1.7071L5.7071 9.7071C5.31657 10.0976 4.68341 10.0976 4.29288 9.7071L0.292891 5.7071C-0.097629 5.31657 -0.097629 4.68341 0.292891 4.29288C0.683411 3.90236 1.31658 3.90236 1.7071 4.29288L5 7.58578L4.29288 8.29289L4.99999 8.99999L5.7071 8.29288L5 7.58578L12.2929 0.29289C12.6834 -0.09763 13.3166 -0.09763 13.7071 0.29289Z"
            fill="#FCFCFD"
        />
    </svg>
);

interface ICheckBoxProps {
    active: boolean;
    className?: string;
    onClick?: () => void;
}

const Box = styled.div<{ active: boolean }>`
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-color: ${({ active, theme }: { active: boolean; theme: ITheme }) =>
        active ? theme.blue1 : theme.white};
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    display: flex;
`;

const CheckBoxIcon = ({ active, className, onClick }: ICheckBoxProps) => (
    <Box className={`${className}`} onClick={onClick} active={active}>
        {active && <CheckVector />}
    </Box>
);

export default CheckBoxIcon;
