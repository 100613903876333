import React, { memo, useState } from 'react';
import { Checkbox } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import withConnect from 'src/hoc/withConnect';
import { isMainnet } from 'src/configs/Configs.env';
import { actionCloseModal } from 'src/components/Modal/Modal.actions';
import { ReactComponent as CheckedSvg } from 'src/assets/svg/checked.svg';
import useActiveWeb3React from 'src/hooks/useActiveWeb3React';
import { useWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-ant-design';
import { ChainId } from 'src/configs/Connect/Configs.chainId';
import { Wrapper } from './CustomWalletModal.styled';
import { ACTION_TYPES } from '../Header/redux/Wallet.action';
import { IWallet, INetWork, networkList } from './HardData';

interface IWalletModalProps {
    tryActivation: (connector: any) => void;
}

const CustomWalletModal = ({ tryActivation }: IWalletModalProps & any) => {
    const { deactivate } = useActiveWeb3React();
    const [activeNetwork, setActiveNetwork] = useState<any>('');
    const [activeWallet, setActiveWallet] = useState('');
    const [checkbox, setCheckbox] = useState(false);
    const [wallets, setWallets] = useState<IWallet[]>([]);
    const { disconnect } = useWallet();

    const network = useSelector((state: any) => state.wallet);
    const dispatch = useDispatch();
    function checkBoxChange(e: any) {
        if (e.target.checked === false) {
            setActiveWallet('');
            setActiveNetwork('');
            setWallets([]);
        }
        setCheckbox(e.target.checked);
    }

    function onChangeNetwork(chainId: string) {
        if (checkbox) {
            setActiveNetwork(chainId);
            const arrWallet: IWallet[] =
                networkList.find((item: INetWork) => item.chainNumb === chainId)?.wallets || [];
            setWallets(arrWallet);
        }
    }

    function getImgNetwork(item: INetWork) {
        return (
            <img
                src={require(`src/assets/svg/network-logos/${checkbox ? item.icon : item.iconDisabled}`).default}
                alt="Icon"
            />
        );
    }

    function handleWallet(item: IWallet) {
        if (item.name === 'WalletConnect' || item.name === 'Binance') {
            // if (item.name !== 'Binance') {
            tryActivation(item.connector);
            // }
        } else {
            if (network.wallet !== item.name) {
                console.log('go here');
                deactivate();
            }
            setActiveWallet(item.name);
            localStorage.setItem('connector', 'injected');
            disconnect();
            tryActivation(item.connector);
            dispatch({
                type: ACTION_TYPES.SET_CHAIN_ID,
                payload: activeNetwork,
            });
            dispatch({
                type: ACTION_TYPES.SET_WALLET,
                payload: item.name,
            });
            dispatch(actionCloseModal());
        }
    }

    const chooseWallet = () => {
        if (activeNetwork === 'solana') {
            return (
                <div className="wallets-solana">
                    <WalletMultiButton
                        className="custom-btn-multi-solana"
                        onClick={(e: any) => {
                            if (
                                e.target.innerHTML === 'Select Wallet' ||
                                e.target.innerHTML === '<span>Select Wallet</span>'
                            ) {
                                deactivate();
                                localStorage.removeItem('connector');
                                dispatch({
                                    type: ACTION_TYPES.SET_CHAIN_ID,
                                    payload: null,
                                });
                                dispatch(actionCloseModal());
                            }
                        }}
                    />
                </div>
            );
        }
        return (
            <div className="wallets">
                {wallets.map((item) => {
                    let classWallet = 'wallet';
                    if (item.name === activeWallet) {
                        classWallet += ' active-wallet';
                    }
                    return (
                        <div
                            key={item.name}
                            className={classWallet}
                            onClick={() => {
                                handleWallet(item);
                            }}
                        >
                            <div className="button-wallet">
                                <img src={require(`src/assets/svg/wallet-logos/${item.icon}`).default} alt="Icon" />
                            </div>
                            <div className="name-wallet">{item.name}</div>
                        </div>
                    );
                })}
            </div>
        );
    };
    return (
        <Wrapper>
            <div className="options">
                <div className="accept-service">
                    <div className="title">
                        1. Accept <span> Terms of Service</span> and <span>Privacy Policy</span>
                    </div>
                    <div className="checkbox">
                        <Checkbox onChange={(e) => checkBoxChange(e)}>
                            I read and accept
                            {/* {!checkbox && (
                                <span style={{ marginLeft: 3 }}>
                                    (<span style={{ color: 'red', fontSize: '12px' }}>accept to choose Network</span>)
                                </span>
                            )} */}
                        </Checkbox>
                    </div>
                </div>
                <div className="choose-network">
                    <div className="title">2. Choose Network</div>
                    <div className="networks">
                        {networkList.map((item) => {
                            let classNetwork = 'network';
                            if (item.chainNumb === activeNetwork && checkbox) {
                                classNetwork += ' active-network';
                            }
                            if (isMainnet && item.chainNumb === ChainId.BSC_TESTNET) {
                                return <></>;
                            }
                            return (
                                <div
                                    className={classNetwork}
                                    key={item.name}
                                    onClick={() => onChangeNetwork(item.chainNumb)}
                                >
                                    <div className="button-network">
                                        {getImgNetwork(item)}
                                        {item.chainNumb === activeNetwork && <CheckedSvg className="checked" />}
                                    </div>
                                    <div className="name-network">{item.name}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="choose-wallets">
                    <div className="title">3. Choose Wallet</div>
                    {chooseWallet()}
                </div>
            </div>
        </Wrapper>
    );
};

export default withConnect(memo(CustomWalletModal));
