import { lazy } from 'react';
import { IRouteProps } from 'src/modules';

export const route = '/nft-details/:id';

const Bidding: IRouteProps = {
    path: route,
    exact: true,
    component: lazy(() => import('src/modules/NFTDetails')),
    name: 'NFT Details - Bidding',
};

export default Bidding;
