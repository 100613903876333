import styled, { ITheme } from 'styled-components';
import { Row } from 'antd';

export const FooterFrame = styled(Row)`
    margin-top: 200px;
    position: relative;
    max-width: 100vw;
    .svg-bg {
        svg {
            height: 80%;
            position: absolute;
            bottom: 0;
            width: 100%;
        }
    }
    .footer-mobile {
        display: none;
    }
    .footer-mobile-content {
        display: none;
    }
    .ant-anchor-ink {
        display: none;
    }
    .ant-anchor-wrapper {
        overflow: unset;
        width: 99vw !important;
    }
    .content-footer {
        height: 300px;
        max-width: 1400px;
        width: 100%;
        padding: 0 80px;
        margin: 0 auto;
        .center {
            color: white;

            .ant-row {
                width: 100%;
            }
            .column-center {
                .title {
                    margin-bottom: 8px;
                    font-family: 'Lexend';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: -0.125px;
                    font-feature-settings: 'salt' on;
                    color: #707070;
                }
                .custom-content {
                    display: flex;
                    .content {
                        margin-right: 24px;
                    }
                }
                .content {
                    width: fit-content;
                    margin-top: 8px;
                    font-family: 'Lexend';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: -0.125px;
                    font-feature-settings: 'salt' on;
                    color: #ffffff;
                    cursor: pointer;
                    transition: 0.3s;
                    &:hover {
                        transform: scale(1.05);
                    }
                }
            }
            .column-center {
                .ant-anchor-ink {
                    display: none !important;
                }
                .ant-anchor-link {
                    padding: 0;
                    .ant-anchor-link-title {
                        margin-top: 8px;
                        font-family: 'Lexend';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: -0.125px;
                        font-feature-settings: 'salt' on;
                        color: #ffffff;
                        cursor: pointer;
                        transition: 0.3s;
                        &:hover {
                            transform: scale(1.01);
                        }
                    }
                }
                .ant-anchor-link-active {
                    color: #ffffff;
                }
            }
        }
        .right {
            display: flex;
            justify-content: flex-end;

            .social {
                .title-social {
                    font-family: 'Lexend';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: -0.125px;
                    font-feature-settings: 'salt' on;
                    color: #707070;
                }
                .list-social {
                    margin-top: 20px;
                    display: flex;
                    .facebook,
                    .telegram {
                        margin-left: 24px;
                    }
                    .facebook,
                    .telegram,
                    .twitter {
                        cursor: pointer;
                        transition: 0.3s;
                        .ant-image-img {
                            width: 24px;
                            height: 24px;
                        }
                        &:hover {
                            transform: scale(1.05);
                        }
                    }
                }
            }
        }
    }
    ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
        margin-top: 30px;
        position: relative;
        width: 100%;
        height: 100%;
        .content-footer {
            height: auto !important;
        }
        .desk-top {
            display: none !important;
        }
        .svg-bg {
            .footer-mobile {
                display: block;
                position: absolute;
                bottom: 0;
                height: 200px;
            }
        }
        .ant-anchor-ink {
            display: none;
        }
        .ant-anchor-wrapper {
            overflow: unset;
        }
        .footer-mobile-content {
            padding-bottom: 30px;
            display: block;
            .ant-col-xs-12 {
                margin-top: 32px;
                .header-title {
                    font-family: 'Lexend';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: -0.125px;
                    font-feature-settings: 'salt' on;
                    color: #707070;
                    margin-bottom: 8px;
                }
                .content-title {
                    margin-top: 8px;
                    font-family: 'Lexend';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: -0.125px;
                    font-feature-settings: 'salt' on;
                    color: #FFFFFF;
                }
                .ant-anchor-link {
                    padding: 0;
                    a {
                        margin-top: 8px;
                        font-family: 'Lexend';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        letter-spacing: -0.125px;
                        font-feature-settings: 'salt' on;
                        color: #ffffff;
                        cursor: pointer;
                        transition: 0.3s;
                        &:hover {
                            transform: scale(1.05);
                        }
                    }
                }
                .ant-anchor-link-active {
                    color: #ffffff;
                }
                .social {
                    .title-social {
                        font-family: 'Lexend';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: -0.125px;
                        font-feature-settings: 'salt' on;
                        color: #707070;
                        margin-bottom: 8px;
                    }
                    .list-social {
                        display: flex;
                        .facebook,
                        .telegram {
                            margin-left: 24px;
                        }
                        .facebook,
                        .telegram,
                        .twitter {
                            cursor: pointer;
                            transition: 0.3s;
                            .ant-image-img {
                                width: 24px;
                                height: 24px;
                            }
                            &:hover {
                                transform: scale(1.05);
                            }
                        }
                    }
                }
            }
        }
        .content-footer {
            padding: 0 30px;
            .logo-footer {
                width: 141px;
                height: 40px;
            }
            
        }
        
    `}
`;
