import { IInventory, ACTION_TYPES } from './Inventory.type';

const initialState: IInventory = {
    items: [],
    nextPage: {
        pageIndex: 0,
        take: 0,
        total: 0,
    },
    isEnd: true,
};
const inventoryReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ACTION_TYPES.ACTION_INVENTORY_GET_BY_ID: {
            return {
                ...state,
                ...action.payload,
            };
        }

        default: {
            return { ...state };
        }
    }
};

export default inventoryReducer;
