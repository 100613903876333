export const FONT_SIZES = {
    superSmall: 12,
    small: 14,
    regular: 16,
    medium: 18,
    superMedium: 20,
    large: 22,
    avgLarge: 30,
    veryLarge: 40,
    superLarge: 60,
};

export const FONT_STYLES = {
    regular: 'Poppins-Regular',
    medium: 'Poppins-Medium',
    bold: 'Poppins-Bold',
};

export const FONTS = {
    SIZE: FONT_SIZES,
    STYLE: FONT_STYLES,
};
