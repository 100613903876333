import styled, { ITheme } from 'styled-components';

export const Wrapper = styled.div`
    margin-top: 32px;
    .title {
        font-style: normal;
        font-size: 26px;
        color: white;
        width: 100%;
    }

    .options {
        margin-bottom: 32px;

        .accept-service {
            .title {
                font-size: 16px;
                color: ${({ theme }: { theme: ITheme }) => theme.text7};
                span {
                    color: #3772ff;
                }
            }

            .checkbox {
                margin-top: 20px;
                span {
                    font-size: 14px;
                    color: ${({ theme }: { theme: ITheme }) => theme.text7};
                }
            }
        }
        .choose-wallets {
            margin-top: 32px;
            .title {
                color: ${({ theme }: { theme: ITheme }) => theme.text7};
                font-size: 16px;
            }
            .wallets {
                margin-top: 8px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex-wrap: wrap;
                .wallet {
                    cursor: pointer;
                    border-radius: 20px;
                    width: 122px;
                    height: auto;
                    margin-top: 12px;
                    &.active-wallet {
                        background: #141416;
                    }
                    .button-wallet {
                        display: flex;
                        justify-content: center;
                        img {
                            margin-top: 8px;
                            width: 60px;
                            height: 60px;
                            border-radius: 16px;
                        }
                    }
                    .name-wallet {
                        margin-top: 8px;
                        display: flex;
                        justify-content: center;
                        color: ${({ theme }: { theme: ITheme }) => theme.text7};
                    }
                }
            }
            .wallets-solana {
                display: flex;
                justify-content: center;

                .custom-btn-multi-solana {
                    margin-top: 15px;
                    height: 40px;
                    padding: 10px 20px;
                    font-size: 16px !important;
                    font-weight: 700;
                    border: 2px solid rgb(119, 126, 144);
                    background: transparent !important;
                    border-radius: 25px;
                    display: flex;
                    align-items: center;
                }
            }
        }
        .choose-network {
            margin-top: 32px;
            .title {
                color: ${({ theme }: { theme: ITheme }) => theme.text7};
                font-size: 16px;
            }

            .networks {
                margin-top: 20px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
                .network {
                    cursor: pointer;
                    border-radius: 20px;
                    width: 122px;
                    height: auto;
                    &.active-network {
                        background: #141416;
                    }
                    .button-network {
                        display: flex;
                        justify-content: center;
                        position: relative;
                        img {
                            margin-top: 8px;
                            width: 60px;
                            height: 60px;
                            border-radius: 16px;
                        }
                        .checked {
                            position: absolute;
                            top: 48px;
                            left: 75px;
                        }
                    }
                    .name-network {
                        margin-top: 8px;
                        display: flex;
                        justify-content: center;
                        color: ${({ theme }: { theme: ITheme }) => theme.text7};
                    }
                }
            }
        }
    }

    ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
        .choose-network {
            .networks {
                display: flex;
                justify-content: space-evenly;
                margin-top: 20px;
                .network {
                    width: 100px !important;

                    .button-network {
                        .checked {
                            left: 60px !important;
                        }
                    }
                }
            }
        }

        .wallets {
            display: flex;
            justify-content: space-evenly;
            margin-top: 20px;
            .wallet {
                width: 100px !important;
            }
        }
    `}
`;
