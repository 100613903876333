export const ACTION_TYPES = {
    ACTION_UPDATE_ACCOUNT: '[account] action update account',
    ACTION_UPDATE_ACCESS_TOKEN: '[account] action update access token',
    ACTION_REMOVE_ACCESS_TOKEN: '[account] action remove specific',
};

export interface IToken {
    address: string;
    token: string;
    nonce: number;
}

export interface AccountState {
    address?: string;
    token?: IToken[];
}

export interface updateAccountType {
    type: string;
    payload: AccountState;
}

export type AccountActions = updateAccountType;
