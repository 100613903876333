import { lazy } from 'react';
import { IRouteProps } from 'src/modules';

export const route = '/coming-soon';

const HomeRoute: IRouteProps = {
    path: route,
    exact: false,
    component: lazy(() => import('src/modules/ComingSoon')),
    name: 'Coming Soon',
};

export default HomeRoute;
