import { lazy } from 'react';
import { IRouteProps } from 'src/modules';

export const route = '/faq';

const NFTDetails: IRouteProps = {
    path: route,
    exact: true,
    component: lazy(() => import('src/modules/FAQ')),
    name: 'FAQ - Tuniver',
};

export default NFTDetails;
