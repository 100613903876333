import React, { useEffect, useMemo } from 'react';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router';
import { MainRoute } from 'src/modules';
import { ThemeProvider, ThemedGlobalStyle } from 'src/theme';
import Header from 'src/components/Header';
import Modal from 'src/components/Modal';
import Footer from 'src/components/Footer';
import Loading from 'src/components/Loading';
import PopUpSign from 'src/components/PopupSign/PopupSign';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { clusterApiUrl } from '@solana/web3.js';
import { WalletAdapterNetwork, WalletError } from '@solana/wallet-adapter-base';
import { getPhantomWallet, getSolflareWallet } from '@solana/wallet-adapter-wallets';
import { WalletProvider, ConnectionProvider } from '@solana/wallet-adapter-react';
import { HelmetProvider } from 'react-helmet-async';
import { WalletModalProvider } from '@solana/wallet-adapter-ant-design';
import { isMainnet } from 'src/configs/Configs.env';
import { QueryClient, QueryClientProvider } from 'react-query';

import dynamic from 'next/dynamic';
import Web3ReactManager from '../components/Web3ReactManager';
import ScrollToTop from '../components/ScrollToTop/ScrollToTop';
import withApp from './App.enhance';
import './reset.scss';
import 'antd/dist/antd.css';
import '@solana/wallet-adapter-ant-design/styles.css';

const history = createBrowserHistory();
const Web3ProviderNetwork = dynamic(() => import('src/components/Web3ProviderNetwork'), { ssr: false });
const queryClient = new QueryClient();

function getLibrary(provider: any) {
    return new Web3Provider(provider); // this will vary according to whether you use e.g. ethers or web3.js
}

// const injected = localStorage.getItem('connector');
const App: React.FunctionComponent = () => {
    const network = isMainnet ? WalletAdapterNetwork.Mainnet : WalletAdapterNetwork.Devnet;
    const endpoint = useMemo(() => clusterApiUrl(network), [network]);

    const wallets = useMemo(() => [getPhantomWallet(), getSolflareWallet()], [network]);

    return (
        <HelmetProvider>
            <QueryClientProvider client={queryClient}>
                <ConnectionProvider endpoint={endpoint}>
                    <WalletProvider wallets={wallets} autoConnect>
                        <WalletModalProvider>
                            <Web3ReactProvider getLibrary={getLibrary}>
                                <Web3ProviderNetwork getLibrary={getLibrary}>
                                    <Web3ReactManager>
                                        <ThemeProvider>
                                            <ThemedGlobalStyle />
                                            <Router history={history}>
                                                <ScrollToTop>
                                                    <PopUpSign />
                                                    <Header />
                                                    <MainRoute />
                                                    <Footer />
                                                    <Modal />
                                                    <Loading />
                                                </ScrollToTop>
                                            </Router>
                                        </ThemeProvider>
                                    </Web3ReactManager>
                                </Web3ProviderNetwork>
                            </Web3ReactProvider>
                        </WalletModalProvider>
                    </WalletProvider>
                </ConnectionProvider>
            </QueryClientProvider>
        </HelmetProvider>
    );
};

export default withApp(React.memo(App));
