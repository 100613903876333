import React, { memo } from 'react';
import { Dropdown, Menu, Row } from 'antd';
import styled, { ITheme } from 'styled-components';
import { useDispatch } from 'react-redux';
import useActiveNetwork from 'src/hooks/useActiveNetwork';
import useActiveWallet from 'src/hooks/useActiveWallet';
import withConnect from 'src/hoc/withConnect';
import { ArrowIcon } from 'src/components/Icons';
import { ACTION_TYPES } from '../redux/Wallet.action';

const Button = styled.button`
    :disabled {
        opacity: 0.5;
        :hover {
            cursor: pointer;
            transform: scale(1);
        }
    }
    .logo {
        margin-left: 16px;
        width: 24px;
        height: 24px;
        justify-content: center;
    }
    .icon-arrow {
        margin-left: 10px;
    }

    ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
        background: none !important;
        border: 1px solid ${theme.border1} !important;
        margin-bottom: 8px !important;
        color: white !important;
    `}
`;

interface IBTNProps {
    className?: string;
    onClick?: () => void;
    item?: any;
}

const BTNNetwork = (props: IBTNProps & React.ButtonHTMLAttributes<HTMLButtonElement>) => {
    const { className, onClick, item, ...rest } = props;
    return (
        <Button className={`btn-primary ${className}`} onClick={onClick} {...rest}>
            {item.chainName}
            <ArrowIcon className="icon-arrow" />
        </Button>
    );
};

const MenuContainer = styled(Menu)`
    border-radius: 16px;
    .logo {
        margin-right: 16px;
        width: 24px;
        height: 24px;
        justify-content: center;
    }
`;

const DropdownNetwork = ({ changeChainId }: any) => {
    const activeNetwork = useActiveNetwork();
    const activeWallet = useActiveWallet();
    const dispatch = useDispatch();
    const onChooseNetwork = (item: any) => {
        dispatch({
            type: ACTION_TYPES.SET_CHAIN_ID,
            payload: item.chainNumb,
        });
    };
    const menus = () => (
        <MenuContainer theme="dark" mode="horizontal">
            {(activeWallet.supportNetworks || []).map((item: any) => {
                return (
                    <Menu.Item key={item.chainId} onClick={() => changeChainId(item.chainNumb)}>
                        <Row>
                            <img
                                className="logo"
                                alt=""
                                src={require(`src/assets/svg/network-logos${item.path}`).default}
                            />
                            <p>{item.chainName}</p>
                        </Row>
                    </Menu.Item>
                );
            })}
        </MenuContainer>
    );

    if (!activeNetwork || !activeWallet || !activeWallet.supportNetworks) return null;

    return (
        <Dropdown overlay={menus} placement="bottomRight">
            <BTNNetwork className="btn-network" item={activeNetwork} />
        </Dropdown>
    );
};

export default memo(withConnect(DropdownNetwork));
