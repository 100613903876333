import { StringLiteralLike } from 'typescript';

const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAIL = 'FAIL';

export const createRequestTypes = (
    base: string,
): {
    REQUEST: string;
    SUCCESS: string;
    FAIL: string;
} => ({
    [REQUEST]: `${base}_${REQUEST}`,
    [SUCCESS]: `${base}_${SUCCESS}`,
    [FAIL]: `${base}_${FAIL}`,
});
