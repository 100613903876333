import * as React from 'react';

interface IIconEyeProps {
    show: boolean;
    className?: string;
}

const EyeHideIcon = (props: IIconEyeProps & any) => (
    <svg width={24} height={20} viewBox="0 0 24 20" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 15.9999C16.1255 15.9999 19.0592 13.4076 20.7773 11.2486C21.3732 10.4998 21.3732 9.50012 20.7773 8.75129C19.0592 6.59232 16.1255 3.99994 12 3.99994C7.87451 3.99994 4.9408 6.59232 3.22274 8.75129C2.62684 9.50012 2.62684 10.4998 3.22274 11.2486C4.9408 13.4076 7.8745 15.9999 12 15.9999ZM22.3422 12.4939C23.5182 11.0162 23.5182 8.98368 22.3422 7.50594C20.4739 5.1582 17.0265 1.99994 12 1.99994C6.97347 1.99994 3.52606 5.1582 1.65778 7.50594C0.48183 8.98368 0.481829 11.0162 1.65778 12.4939C3.52606 14.8417 6.97347 17.9999 12 17.9999C17.0265 17.9999 20.4739 14.8417 22.3422 12.4939Z"
            fill="#777E91"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.6139 0.210633C20.0499 0.549703 20.1284 1.17798 19.7893 1.61393L5.78934 19.6139C5.45027 20.0499 4.82199 20.1284 4.38604 19.7893C3.9501 19.4503 3.87156 18.822 4.21063 18.386L18.2106 0.386044C18.5497 -0.0499029 19.178 -0.128437 19.6139 0.210633Z"
            fill="#777E91"
        />
    </svg>
);

const EyeShowIcon = (props: IIconEyeProps & any) => (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 18C16.1255 18 19.0592 15.4076 20.7772 13.2486C21.3731 12.4998 21.3731 11.5002 20.7772 10.7514C19.0592 8.59239 16.1255 6 12 6C7.87451 6 4.9408 8.59238 3.22274 10.7513C2.62685 11.5002 2.62685 12.4998 3.22274 13.2486C4.9408 15.4076 7.87451 18 12 18ZM22.3422 14.494C23.5182 13.0163 23.5182 10.9837 22.3422 9.506C20.4739 7.15826 17.0265 4 12 4C6.97347 4 3.52606 7.15826 1.65778 9.506C0.48183 10.9837 0.481829 13.0163 1.65778 14.494C3.52606 16.8417 6.97347 20 12 20C17.0265 20 20.4739 16.8417 22.3422 14.494Z"
            fill="#777E91"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 11.9716 10.0006 11.9434 10.0018 11.9153C10.1576 11.9701 10.3253 12 10.5 12C11.3284 12 12 11.3284 12 10.5C12 10.3253 11.9701 10.1576 11.9153 10.0018C11.9434 10.0006 11.9716 10 12 10C13.1046 10 14 10.8954 14 12ZM16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12Z"
            fill="#777E91"
        />
    </svg>
);

const EyeIcon = (props: IIconEyeProps & any) => {
    const { show } = props || {};
    if (show) return <EyeShowIcon {...props} />;
    return <EyeHideIcon {...props} />;
};

export default EyeIcon;
