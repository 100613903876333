export const ACTION_TYPES = {
    ACTION_INVENTORY_GET_BY_ID: '[INVENTORY] action get inventory by id',
};

export interface ItemInventory {
    id: string;
    imageUrl: string;
    notConverted: boolean;
    numNft: number;
    sell: number;
    start: any;
    swap: boolean;
    term: any;
    textBtn: string;
    tokenId: string;
    txtHeader: string;
    owner: string;
    rewards: {
        id: string;
        image: string;
        title: string;
        description: string;
        redeemable: boolean;
    }[];
    rarity: string;
    royaltyShare: number;
    royaltyShares: [];
    tokenNo: number;
    image: string;
    hint: string;
    progress: number;
    royalty: number;
    strokeColor: any;
    videoUrl?: string;
}
export interface IInventory {
    items: ItemInventory[];
    nextPage: {
        pageIndex: number;
        take: number;
        total: number;
    };
    isEnd: boolean;
}
