import { lazy } from 'react';
import { IRouteProps } from 'src/modules';

export const route = '/nft-detail/:id';

const NFTDetail: IRouteProps = {
    path: route,
    exact: true,
    component: lazy(() => import('src/modules/NFTDetail')),
    name: 'NFT Detail',
};

export default NFTDetail;
