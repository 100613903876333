import { createSelector } from 'reselect';
import { IRootState } from '../interface';
import { IToken } from './Account.type';

export const accountSelector = createSelector(
    (state: IRootState) => state.account,
    (account) => account,
);

export const addressSelector = createSelector(accountSelector, (account) => (account.address || '')?.toLowerCase());

export const tokenSelector = createSelector(accountSelector, (account) => account.token);

export const accessTokenSelector = createSelector(addressSelector, tokenSelector, (address, tokens) =>
    tokens?.find((token: IToken) => token.address === address && !!token.token),
);
