import styled, { ITheme } from 'styled-components';
import { CheckCircleIcon, ClipboardCopyIcon } from '@heroicons/react/outline';
import React, { FC } from 'react';
import useCopyClipboard from 'src/hooks/useCopyClipboard';
import { classNames } from 'src/functions';
import Typography from 'src/components/Typography';

interface CopyHelperProps {
    className?: string;
    toCopy: string;
    children?: React.ReactNode;
}

const Wrapper = styled.p`
    color: ${({ theme }: { theme: ITheme }) => theme.text3};
    cursor: pointer;
    :hover {
        color: ${({ theme }: { theme: ITheme }) => theme.text5};
    }
`;

const CopyHelper: FC<CopyHelperProps> = ({ className, toCopy, children }) => {
    const [isCopied, setCopied] = useCopyClipboard();

    return (
        <Wrapper className={classNames('flex items-center', className || '')} onClick={() => setCopied(toCopy)}>
            {isCopied && (
                <div className="items-center">
                    <Typography className="fs-regular">Copied</Typography>
                    <CheckCircleIcon width={16} height={16} />
                </div>
            )}

            {!isCopied && (
                <>
                    {children}
                    <ClipboardCopyIcon width={16} height={16} />
                </>
            )}
        </Wrapper>
    );
};

export default CopyHelper;
