import React from 'react';
import ReactDOM from 'react-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FixedGlobalStyle } from 'src/theme';
import TimeAgo from 'javascript-time-ago';

import en from 'javascript-time-ago/locale/en.json';
import ru from 'javascript-time-ago/locale/ru.json';
import App from './app/App';

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);

require('dotenv').config();

ReactDOM.render(
    <React.StrictMode>
        <FixedGlobalStyle />
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);
