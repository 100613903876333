import { AxiosResponse } from 'axios';
import http from 'src/services/http';
import { camelCaseKeys } from 'src/utils/camelcase';
import { IToken } from 'src/redux/account/Account.type';
import Web3 from 'web3';

export const apiGetNonceWithAddress = (address: string): Promise<AxiosResponse<IToken>> => {
    return http.get(`auth/wallet-status?address=${address}`).then((res: any) => camelCaseKeys(res));
};
export const apiGetNonceByRegister = (address: string, chainId: number | string): Promise<AxiosResponse<IToken>> => {
    return http
        .post('/auth/wallet', {
            address,
            chainId,
        })
        .then((res: any) => camelCaseKeys(res))
        .catch((err) => console.log('err', err));
};

export const getSignature = async (publicAddress: string, nonce: number, library: any): Promise<string> => {
    const web3 = new Web3(library?.provider);
    const message = `${nonce}`;
    const encode = web3.utils.fromUtf8(message);
    return new Promise((resolve, reject) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        web3.eth.personal.sign(encode, publicAddress, (err, signature) => {
            if (err) return reject(err);

            return resolve(signature);
        });
    });
};
export const apiGetAccessToken = (address: string, signature: string) => {
    return http
        .post('auth/wallet/token', {
            address,
            signature: signature.toLowerCase(),
        })
        .then((res: any) => camelCaseKeys(res));
};
