import { onMoveAnimation } from 'src/services/useDevelopUI';
import { ACTION_TYPES } from './Modal.constant';
import { IModalOverlay, IPropsModal } from './Modal.type';

const initialState: IModalOverlay = {
    active: '',
    prev: '',
};

const reducer = (state = initialState, action: { type: string; payload: IPropsModal }) => {
    switch (action.type) {
        case ACTION_TYPES.OPEN_MODAL: {
            setTimeout(() => onMoveAnimation(`modal-${action.payload.title}`, 'moveInOpacity'), 50);
            return {
                ...state,
                prev: state.active,
                active: action.payload.title,
            };
        }
        case ACTION_TYPES.CLOSE_MODAL: {
            const title = state.active;
            onMoveAnimation(`modal-${title}`, 'moveOutOpacity');
            return {
                ...state,
                prev: state.active,
                active: '',
            };
        }
        default:
            return state;
    }
};

export default reducer;
