import styled, { ITheme } from 'styled-components';
import { Row } from 'antd';

export const Wrapper = styled(Row)`
    ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToLarge`
        .ant-anchor-wrapper {
            margin-left: unset;
            padding-left: unset;
            overflow: unset;
        }
        .ant-anchor-ink {
            display: none;
        }
        .ant-anchor-link-title-active {
            color: white !important;
        }
        .ant-menu.ant-menu-dark,
        .ant-menu-dark .ant-menu-sub,
        .ant-menu.ant-menu-dark .ant-menu-sub {
            background: rgb(20, 20, 22);
        }

        .ant-menu-item-selected,
        .ant-menu-item-active {
            background: rgb(20, 20, 22) !important;

            .ant-menu-title-content {
                color: ${theme.text7} !important;
            }
        }

        .ant-menu-title-content {
            font-weight: 600 !important;
            font-size: 16px;
        }

        .ant-menu-submenu-title,
        .ant-menu-item.ant-menu-item-only-child
        {
            padding-left: 0 !important;
            margin-top: 16px;
        }

        .ant-menu-submenu-arrow {
            right: 0 !important;
        }
        .custom-submenu {
            .ant-menu-item {
                font-size: 14px;
                font-weight: 600;
                border-top: 1px solid #525252;
            }
        }

        .ant-menu-submenu-open {
            .submenu-main {
                color: ${theme.text7} !important;
            }
        }
        .ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content,
        .ant-menu-inline.ant-menu-root .ant-menu-submenu-title > .ant-menu-title-content {
            color: rgb(94, 99, 114);
             
            a {
                color: rgb(94, 99, 114);
            }
        }

        .custom-submenu {
            padding-left: 20px !important;
        }
    `}
`;
