import * as React from 'react';

const ArrowIcon = ({ className }: { className?: string }) => (
    <svg width={14} height={8} viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <path
            d="M7.16663 7.99512C7.50842 7.99512 7.79553 7.86523 8.06213 7.5918L13.1207 2.41699C13.3258 2.21191 13.4283 1.96582 13.4283 1.66504C13.4283 1.05664 12.943 0.564453 12.3414 0.564453C12.0475 0.564453 11.7672 0.6875 11.5416 0.913086L7.17346 5.4248L2.79846 0.913086C2.57288 0.6875 2.2926 0.564453 1.99182 0.564453C1.39026 0.564453 0.904907 1.05664 0.904907 1.66504C0.904907 1.95898 1.00745 2.20508 1.21252 2.41699L6.27112 7.5918C6.55139 7.87207 6.83167 7.99512 7.16663 7.99512Z"
            fill="#777E90"
        />
    </svg>
);

export default ArrowIcon;
