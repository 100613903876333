/* eslint-disable react-hooks/rules-of-hooks */
import React, { memo } from 'react';
import styled, { ITheme } from 'styled-components';
import useActiveWeb3React from 'src/hooks/useActiveWeb3React';
import { useSelector } from 'react-redux';
import enhance from 'src/hoc/WalletConnect.enhanceSignin';
import { tokenSelector } from 'src/redux/account/Account.selector';

const ButtonSign = styled.button`
    width: fit-content !important;
    height: 40px !important;
    padding-right: 20px;
    padding-left: 20px;
    margin-right: 12px;
    background: transparent !important;
    color: white !important;
    border: 1px solid white !important;
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: space-around;
    align-items: center;
    p {
        font-size: 16px;
        font-weight: 600;
        font-family: 'Open Sans';
    }
    .logo {
        margin-left: 10px;
        width: 24px;
        height: 24px;
        justify-content: center;
    }
    ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
        margin-right: 0;
        justify-content: center;
        width: 100% !important;
        padding-right: 0px;
        padding-right: 20px;
        padding-left: 0px;
        margin-right: 0px;
        flex:1;
        margin-bottom:8px;
        
        .logo {
           padding-right: 10px;
        }
    `}
`;

const ButtonSignin = ({ onSignIn }: any) => {
    const { account } = useActiveWeb3React();
    const tokens = useSelector(tokenSelector);
    const [isSign, setIsSign] = React.useState(false);

    React.useEffect(() => {
        const value = tokens?.find((token) => token.address === account?.toLowerCase() && !!token.token);
        if (value?.token) {
            setIsSign(true);
        } else {
            setIsSign(false);
        }
    }, [account, tokens]);

    if (account && !isSign) {
        return (
            <ButtonSign onClick={() => onSignIn()} className="btn-primary">
                {/* <WalletLogoSVG className={isMobile ? '' : 'logo'} /> */}
                <p>Sign</p>
            </ButtonSign>
        );
    }
    return <div />;
};
export default enhance(ButtonSignin);
