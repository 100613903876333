import React from 'react';
import useActiveWeb3React from 'src/hooks/useActiveWeb3React';
import { SUPPORTED_NETWORKS } from 'src/configs/Connect/Configs.networks';

function useActiveBalance() {
    const { account, library, chainId } = useActiveWeb3React();
    const [balance, setBalance] = React.useState<any>('');
    const [symbol, setSymbol] = React.useState<any>('');
    React.useEffect((): any => {
        if (!!account && !!library) {
            const _chainId: number = chainId as number;
            let stale = false;
            if (SUPPORTED_NETWORKS[_chainId || 0] && SUPPORTED_NETWORKS[chainId || 0].nativeCurrency) {
                setSymbol(SUPPORTED_NETWORKS[_chainId].nativeCurrency.symbol);
            }
            library
                .getBalance(account)
                .then((_balance: any) => {
                    if (!stale) {
                        setBalance(_balance);
                    }
                })
                .catch(() => {
                    if (!stale) {
                        setBalance('');
                    }
                });

            return () => {
                stale = true;
                setBalance('');
            };
        }
    }, [account, library, chainId]); // ensures refresh if referential identity of library doesn't change across chainIds
    return {
        balance,
        symbol,
    };
}

export default useActiveBalance;
