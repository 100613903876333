import styled, { ITheme } from 'styled-components';
import { FONTS } from 'src/theme/Theme.fonts';

export const Wrapper = styled.div`
    .title {
        color: ${({ theme }: { theme: ITheme }) => theme.white};
    }
    .header-row {
        align-items: center;
        justify-content: space-between;
        margin-top: 32px;
    }
    .btn-change {
        height: 35px;
        width: 80px;
    }
    .addressText {
        background-color: ${({ theme }: { theme: ITheme }) => theme.dark9};
        color: ${({ theme }: { theme: ITheme }) => theme.dark3};
        line-height: 40px;
        border-radius: 16px;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 20px;
    }
    .btn-solana {
        height: 35px;
        font-size: 12px !important;
        border: 2px solid rgb(119, 126, 144);
        background: transparent !important;
        border-radius: 25px;
        display: flex;
        align-items: center;
    }
    .term {
        margin-top: 32px;
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
    }
    .term-number {
        background-color: #131823;
        width: 30px;
        height: 30px;
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        .text {
            color: #7f96b8;
            font-size: ${FONTS.SIZE.medium}px;
        }
    }
    .term-title {
        display: flex;
        flex: 1;
        margin-left: 16px;
        color: ${({ theme }: { theme: ITheme }) => theme.white};
        font-size: ${FONTS.SIZE.medium}px;
        .space {
            width: 6px;
        }
        .a {
            color: #5499ff;
        }
    }
    .wrap-term {
        margin-top: 12px;
        padding-left: 44px;
        display: flex;
        flex-direction: row;
    }
    .warp-checkbox {
        border: 2px solid gray;
        background-color: transparent;
    }

    .wrap-term-text {
        flex: 1;
        padding-left: 12px;
        .text {
            color: ${({ theme }: { theme: ITheme }) => theme.white};
            font-size: ${FONTS.SIZE.regular}px;
        }
    }

    .wrap-network {
        margin-top: 12px;
        padding-left: 16px;
        display: flex;
        flex-direction: row;
    }

    .item-row {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .item-img {
        display: flex;
    }

    .item-logo {
        width: 60px;
        height: 60px;
        border-radius: 30px;
        margin-bottom: 8px;
        background-color: #131823;
    }

    .item-circle {
        display: flex;
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 15px;
        bottom: 50px;
        right: 30px;
        border: 2px solid black;
    }
`;
