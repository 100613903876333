import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { ACTION_TYPES, AccountState } from './Account.type';

const initialState: AccountState = {
    address: '',
    token: [],
};

const accountReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ACTION_TYPES.ACTION_UPDATE_ACCOUNT: {
            const { payload } = action;
            return {
                ...state,
                ...payload,
            };
        }
        case ACTION_TYPES.ACTION_UPDATE_ACCESS_TOKEN: {
            const token = action.payload;
            return {
                ...state,
                token,
            };
        }
        default:
            return state;
    }
};

const persistConfig = {
    key: 'account',
    storage,
    whitelist: ['address', 'token'],
    stateReconciler: autoMergeLevel2,
};

export default persistReducer(persistConfig, accountReducer);
