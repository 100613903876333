import { lazy } from 'react';
import { IRouteProps } from 'src/modules';

export const route = '/my-wallet';

const HomeRoute: IRouteProps = {
    path: route,
    exact: true,
    component: lazy(() => import('src/modules/MyWallet')),
    name: 'My Wallet',
};

export default HomeRoute;
