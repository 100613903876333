import styled, { ITheme } from 'styled-components';

export const Wrapper = styled.div`
    font-size: 14px;
    box-sizing: border-box;
    position: fixed;
    top: 93px;
    right: 30px;
    z-index: 999999;

    .notification {
        transition: transform 0.6s ease-in-out;
        animation: toast-in-right 0.7s;
        background: #23262f;
        margin: 12px 0;
        border-radius: 12px;
        width: 285px;
        min-height: 76px;
        padding: 12px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .ant-row {
        align-items: center;
    }

    p {
        flex: 1;
    }

    .success {
        color: ${({ theme }: { theme: ITheme }) => theme.green2};
    }

    .error {
        color: ${({ theme }: { theme: ITheme }) => theme.red1};
    }

    .waring {
        color: ${({ theme }: { theme: ITheme }) => theme.yellow1};
    }

    .waiting {
        color: ${({ theme }: { theme: ITheme }) => theme.blue3};
    }

    .description {
        color: ${({ theme }: { theme: ITheme }) => theme.dark3};
        word-break: break-all;
    }

    .notification:hover {
        box-shadow: 0 0 12px #fff;
        opacity: 1;
        cursor: pointer;
    }

    @keyframes toast-in-right {
        from {
            transform: translateX(100%);
        }
        to {
            transform: translateX(0);
        }
    }
`;
