import React, { FunctionComponent } from 'react';
import { useDispatch } from 'react-redux';
import { actionToggleModal } from 'src/components/Modal/Modal.actions';
import CustomWalletModal from 'src/components/WalletModal/CustomWalletModal';
import ActiveWallet from 'src/modules/Connect/Connect.activeWallet';

const withWalletModal = (WrappedComponent: FunctionComponent) => (props: any) => {
    const dispatch = useDispatch();

    const openConnectWalletModal = () => {
        dispatch(
            actionToggleModal({
                visible: true,
                title: 'Connect a Wallet',
                width: 678,
                data: <CustomWalletModal />,
            }),
        );
    };

    const openConnectDetail = () => {
        dispatch(
            actionToggleModal({
                visible: true,
                title: 'Account',
                data: <ActiveWallet openConnectWalletModal={openConnectWalletModal} />,
            }),
        );
    };

    return (
        <WrappedComponent
            {...{
                ...props,
                openConnectWalletModal,
                openConnectDetail,
            }}
        />
    );
};

export default withWalletModal;
