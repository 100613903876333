import React from 'react';
import styled, { ITheme } from 'styled-components';
import enhance from 'src/hoc/WalletConnect.enhanceSignin';
import useActiveWeb3React from 'src/hooks/useActiveWeb3React';
import { useSelector } from 'react-redux';
import { tokenSelector } from 'src/redux/account/Account.selector';

const Wrapper = styled.div<{ isSign: boolean; account: string | any }>`
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    transition:all .3s;
    opacity: ${({ account, isSign }) => (account && !isSign ? '1;' : '0;')}
    visibility: ${({ account, isSign }) => (account && !isSign ? 'visible;' : 'hidden;')}
    z-index: 80;
    .overlay {
        background-color: rgba(0, 0, 0, 0.9);
    }
    .sign-request {
        background-color: black;
        border: 1px solid white;
        color: white;
        padding: 20px;
        width: 450px;
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
            width: 90vw;
        `}
        .title {
            margin-top:10px;
            font-size: 24px;
            font-weight: 600;
            text-transform: uppercase;
            text-align-center;
            align-self:center;
            ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
                font-size:19px;
            `}
        }
        .message {
            margin-top: 15px;
            text-align:center;
            ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
                margin-top: 8px;
                font-size:12px;
            `}
        }
        .icon {
            width:200px;
            align-self: center;
            ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
                width:140px;
            `}
        }
        .btn-sign {
            padding:6px 0px;
            width:100%;
            border:1px solid white;
            border-radius:20px;
            text-align:center;
            margin-top:10px;
            cursor:pointer;
            transition:all .3s;
            :hover {
                transform:scale(1.02);
            }
            :active {
                transform:scale(0.995);
            }
        }
    }
`;

export const PopUpSign = ({ onSignIn }: any) => {
    const { account } = useActiveWeb3React();
    const tokens = useSelector(tokenSelector);
    const [isSign, setIsSign] = React.useState(true);

    React.useEffect(() => {
        const value = tokens?.find((token) => token.address === account?.toLowerCase() && !!token.token);
        if (value?.token) {
            setIsSign(true);
        } else {
            setIsSign(false);
        }
    }, [account, tokens]);

    return (
        <Wrapper isSign={isSign} account={account}>
            <div className="overlay" />
            <div className="sign-request">
                <img className="icon" src={require('../../assets/image/logo-footer.png').default} alt="Icon" />
                <span className="title">Request sign message</span>
                <span className="message">
                    Warning: Your session appears to have expired. To view the page, please SIGN MESSAGE for a new
                    session.
                </span>

                <div onClick={() => onSignIn()} className="btn-sign">
                    Sign Request
                </div>
            </div>
        </Wrapper>
    );
};

export default enhance(PopUpSign);
