import { ADD_TOAST, createToast, REMOVE_TOAST } from './Toast.action';

export function addToast(options = {}) {
    return {
        payload: createToast(options),
        type: ADD_TOAST,
    };
}

export function removeToast(id: number) {
    return {
        payload: id,
        type: REMOVE_TOAST,
    };
}
