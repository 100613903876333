import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { TOAST_TYPES } from 'src/components/Toast/redux/Toast.action';
import { addToast } from 'src/components/Toast/redux/Toast.service';
import useActiveWeb3React from 'src/hooks/useActiveWeb3React';
import { actionSignIn } from 'src/redux/account/Account.action';
import { accessTokenSelector } from 'src/redux/account/Account.selector';
import { setAccessToken } from '../services/http';

const enhanceSignIn = (WrappedComponent: FunctionComponent) => (props: any) => {
    const dispatch = useDispatch();
    const { account, library } = useActiveWeb3React();
    const token = useSelector(accessTokenSelector);
    const { chainId } = useSelector((state: any) => state.wallet);
    const onSignIn = () => {
        dispatch(actionSignIn(account as string, chainId, library));
    };
    React.useEffect(() => {
        if (token) {
            setAccessToken(token.token || '');
        }
    }, [token]);

    return (
        <WrappedComponent
            {...{
                ...props,
                onSignIn,
            }}
        />
    );
};

export default enhanceSignIn;
