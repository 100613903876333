import React from 'react';
import useActiveWeb3React from 'src/hooks/useActiveWeb3React';
import { SUPPORTED_NETWORKS } from 'src/configs/Connect/Configs.networks';

function useActiveNetwork() {
    const { chainId } = useActiveWeb3React();
    return React.useMemo((): any => SUPPORTED_NETWORKS[chainId || 0], [chainId]); // ensures refresh if referential identity of library doesn't change across chainIds
}

export default useActiveNetwork;
