import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import { persistReducer } from 'redux-persist';
import { IConnectState } from './Connect.type';
import { ACTION_TYPES } from './Connect.constant';

const initialState: IConnectState = {};

const connectReducer = (state = initialState, action: { type: string; payload: any }) => {
    switch (action.type) {
        case ACTION_TYPES.CHOOSE_NETWORK: {
            return {
                ...state,
                ...action.payload,
            };
        }
        default:
            return state;
    }
};

const persistConfig = {
    key: 'connectReducer',
    storage,
    whitelist: [],
    stateReconciler: autoMergeLevel2,
};

export default persistReducer(persistConfig, connectReducer);
