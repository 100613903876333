import { Button, Menu, Row, Drawer, Anchor } from 'antd';
import React, { memo, useEffect, useState } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { NavLink, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReactComponent as IcClose } from 'src/assets/svg/ic_close_drawer.svg';
import { ReactComponent as LogoSVG } from 'src/assets/image/tuniverse/logo.svg';
import withConnect from 'src/hoc/withConnect';
import useActiveWeb3React from 'src/hooks/useActiveWeb3React';
import { ENVS } from 'src/configs/Configs.env';
import { HeaderFrame } from './CustomHeader.styled';
import DropdownNetwork from './components/DropdownNetwork';
import ButtonConnectWallet from './components/ButtonConnectWallet';
import BtnSign from './components/ButtonSign';
import MenuMobile from './components/MenuMobile';
import { menuItemDesktop } from './HardData';

interface IHeaderProps {
    changeChainId: (chainId: number) => void;
}

function CustomHeader({ changeChainId }: IHeaderProps & any): JSX.Element {
    const [width, setWidth] = useState(window.innerWidth);
    const [visible, setVisible] = useState(false);
    const breakpoint = 1300;
    const { account } = useActiveWeb3React();
    // const currentAnchor = Anchor.prototype.getCurrentAnchor();
    // console.log('currentAnchor: ', currentAnchor);

    useEffect(() => {
        if (account && ENVS.REACT_APP_ACTIVE_CHAIN) {
            changeChainId(ENVS.REACT_APP_ACTIVE_CHAIN);
        }
    }, [account]);
    useEffect(() => {
        const handleWindowResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleWindowResize);

        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);
    const openMenu = () => {
        setVisible(true);
    };
    const onClose = () => {
        setVisible(false);
    };
    const MenuDesktop = () => {
        return (
            <Anchor targetOffset={84}>
                <Menu
                    className="menu-custom-header"
                    mode="horizontal"
                    defaultSelectedKeys={['2']}
                    defaultOpenKeys={['3']}
                    inlineCollapsed={false}
                >
                    {menuItemDesktop.map((item) => (
                        <Menu.Item
                            style={{ width: 'auto', marginLeft: 10, paddingLeft: 2, paddingRight: 12 }}
                            key={item.name}
                        >
                            <Link to={item.path}>{item.name}</Link>
                        </Menu.Item>
                    ))}
                </Menu>
            </Anchor>
        );
    };
    return (
        <HeaderFrame>
            <div className="custom-header">
                {width > breakpoint ? (
                    <div className="menu-desktop">
                        <NavLink className="logo" to="/">
                            <LogoSVG />
                        </NavLink>
                        <div className="menu">{MenuDesktop()}</div>
                        <div className="network">
                            <DropdownNetwork />
                            <ButtonConnectWallet />
                            <BtnSign />
                        </div>
                    </div>
                ) : (
                    <div className="menu-mobile">
                        <NavLink className="logo-mobile" to="/">
                            <LogoSVG />
                        </NavLink>
                        <Row className="connect-mobile">
                            {/* <DropdownNetwork /> */}
                            {/* <ButtonConnectWallet className="connect-wallet-mobile" /> */}
                            <Button className="btn-round" type="primary" shape="round" size="large" onClick={openMenu}>
                                <MenuOutlined />
                            </Button>
                        </Row>
                    </div>
                )}
            </div>
            <Drawer
                placement="right"
                width="100%"
                closable
                visible={visible}
                key="right"
                onClose={onClose}
                drawerStyle={{ backgroundColor: '#141416' }}
                closeIcon={<IcClose style={{ marginTop: 8 }} width={20} height={20} />}
            >
                <NavLink className="logo" to="/" onClick={onClose}>
                    <LogoSVG />
                </NavLink>
                <div style={{ marginTop: 30 }}>
                    <BtnSign />
                    <DropdownNetwork />
                    <ButtonConnectWallet />
                </div>
                <div style={{ marginTop: 16 }}>
                    <MenuMobile onClose={onClose} />
                </div>
                {/* <div style={{ position: 'absolute', bottom: 32, left: 24, right: 24 }}>
                    <ButtonConnectWallet />
                </div> */}
            </Drawer>
        </HeaderFrame>
    );
}

export default withConnect(memo(CustomHeader));
