import React from 'react';
import { COMPONENT_IDS } from 'src/app/App.constant';
import LogoFooterImg from 'src/assets/image/logo-footer.png';
import { ReactComponent as FooterSvg } from 'src/assets/svg/footer.svg';
import { ReactComponent as FooterMobileSvg } from 'src/assets/svg/footer-mobile.svg';
import { Col, Image, Row, Anchor } from 'antd';
import TwitterImg from 'src/assets/footer/twitter.png';
import TelegramImg from 'src/assets/footer/telegram.png';
import FacebookImg from 'src/assets/footer/facebook.png';
import DiscordImg from 'src/assets/footer/discord.png';
import { FooterFrame } from './Footer.styled';

const { Link } = Anchor;

const Footer = () => {
    return (
        <FooterFrame id={COMPONENT_IDS.FOOTER}>
            <div className="svg-bg">
                <FooterSvg className="footer desk-top" />
                <FooterMobileSvg className="footer-mobile" />
            </div>

            <Anchor>
                <div className="content-footer">
                    <Row>
                        <Col md={8} xs={24} className="left">
                            <div className="logo-footer">
                                <Image src={LogoFooterImg} preview={false} />
                            </div>
                        </Col>
                        <Col md={24} xs={12} className="footer-mobile-content">
                            <Row>
                                {/* <Col xs={12}>
                                    <div className="header-title">COMPANY</div>
                                    <div className="content-title">About Us</div>
                                    <div className="content-title">Disclaimer Us</div>
                                    <div className="content-title">Privacy Policy</div>
                                </Col>
                                <Col xs={12}>
                                    <div className="header-title">COMMUNITIES</div>
                                    <div className="content-title">
                                        <Link href="#artist" title="For Artist" />
                                    </div>
                                    <div className="content-title">
                                        <Link href="#investor" title="For Investor" />
                                    </div>
                                    <div className="content-title">
                                        <Link href="#team" title="Advisors" />
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className="header-title">COMPANY</div>
                                    <div className="content-title">About Us</div>
                                    <div className="content-title">Disclaimer Us</div>
                                    <div className="content-title">Privacy Policy</div>
                                </Col> */}
                                <Col xs={12}>
                                    <div className="social">
                                        <div className="title-social">Social</div>
                                        <div className="list-social">
                                            <a
                                                href="https://twitter.com/TuniverOfficial"
                                                target="_blank"
                                                className="twitter"
                                                rel="noreferrer"
                                            >
                                                <Image src={TwitterImg} preview={false} />
                                            </a>
                                            <a
                                                href="https://www.facebook.com/tuniver.io"
                                                target="_blank"
                                                className="facebook"
                                                rel="noreferrer"
                                            >
                                                <Image src={FacebookImg} preview={false} />
                                            </a>
                                            <a
                                                href="https://t.me/tuniverofficial"
                                                target="_blank"
                                                className="telegram"
                                                rel="noreferrer"
                                            >
                                                <Image src={TelegramImg} preview={false} />
                                            </a>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} xs={24} className="center desk-top">
                            {/* <Row>
                                <Col md={8} xs={12} className="column-center">
                                    <div className="title">COMPANY</div>
                                    <div className="content">About Us</div>
                                    <div className="content">Disclaimer</div>
                                    <div className="content">Privacy Policy</div>
                                </Col>
                                <Col md={8} xs={12} className="column-center">
                                    <div className="title">COMMUNITIES</div>
                                    <div className="content">
                                        <Link href="#artist" title="For Artist" />
                                    </div>
                                    <div className="content">
                                        <Link href="#investor" title="For Investor" />
                                    </div>
                                    <div className="content">
                                        <Link href="#team" title="Advisors" />
                                    </div>
                                    <div className="content">Vendors</div>
                                </Col>
                                <Col md={{ span: 12, offset: 8 }} xs={12} className="column-center">
                                    <div className="title">INFO</div>
                                    <div className="content">Sitemap</div>
                                    <div className="content">Disclaimer</div>
                                    <div className="custom-content">
                                        <div className="content">Terms Of Use</div>
                                        <div className="content">Privacy Policy</div>
                                    </div>
                                </Col>
                            </Row> */}
                        </Col>
                        <Col md={4} xs={8} className="right desk-top">
                            <div className="social">
                                <div className="title-social">SOCIAL</div>
                                <div className="list-social">
                                    <a
                                        href="https://twitter.com/TuniverOfficial"
                                        target="_blank"
                                        className="twitter"
                                        rel="noreferrer"
                                    >
                                        <Image src={TwitterImg} preview={false} />
                                    </a>
                                    <a
                                        href="https://www.facebook.com/tuniver.io"
                                        target="_blank"
                                        className="facebook"
                                        rel="noreferrer"
                                    >
                                        <Image src={FacebookImg} preview={false} />
                                    </a>
                                    <a
                                        href="https://t.me/tuniverofficial"
                                        target="_blank"
                                        className="telegram"
                                        rel="noreferrer"
                                    >
                                        <Image src={TelegramImg} preview={false} />
                                    </a>
                                    <a
                                        href="https://discord.gg/fWGqsXbWfA"
                                        target="_blank"
                                        className="telegram"
                                        rel="noreferrer"
                                    >
                                        <Image src={DiscordImg} preview={false} />
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Anchor>
        </FooterFrame>
    );
};

export default Footer;
