import React, { useState } from 'react';
import { Menu, Anchor } from 'antd';

import { Link } from 'react-router-dom';
import { Wrapper } from './MenuMobile.styled';
import { menuMobileItem, IMenuMobile, ISubmenu } from '../HardData';

const { SubMenu } = Menu;
const MenuMobile = ({ onClose }: any) => {
    const [collapsed, setCollapsed] = useState(false);
    const handleClick = (e: any) => {
        console.log('click ', e);
    };
    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };
    return (
        <Wrapper>
            <Anchor targetOffset={84}>
                <Menu
                    // defaultSelectedKeys={['1']}
                    // defaultOpenKeys={['sub1']}
                    mode="inline"
                    theme="dark"
                    inlineCollapsed={collapsed}
                >
                    {/* <div className="btn-connect-wallet"></div> */}
                    {menuMobileItem.map((item: IMenuMobile) => {
                        return (
                            <Menu.Item key={item.key} onClick={onClose}>
                                {/* <NavLink target={item?.target} to={item.path}>
                                    {item.name}
                                </NavLink> */}
                                <Link to={item.path}>{item.name}</Link>
                            </Menu.Item>
                        );
                    })}
                    {/* <SubMenu key="sub1" title={<span className="submenu-main">About Us</span>}>
                    <Menu.Item key="1">MemberShip</Menu.Item>
                    <Menu.Item key="2">White Paper</Menu.Item>
                </SubMenu>
                <Menu.Item key="3">Metaverse</Menu.Item>
                <SubMenu key="sub2" title="Marketplace">
                    <Menu.Item key="4">All NFTs</Menu.Item>
                    <Menu.Item key="5">Art</Menu.Item>
                    <Menu.Item key="6">Game</Menu.Item>
                </SubMenu>
                <Menu.Item key="7">Earn</Menu.Item>
                <Menu.Item key="8">Join IDO</Menu.Item>
                <Menu.Item key="9">FAQ</Menu.Item> */}
                </Menu>
            </Anchor>
        </Wrapper>
    );
};

export default MenuMobile;
