import { BscConnector } from '@binance-chain/bsc-connector';
import { ChainId } from 'src/configs/Connect/Configs.chainId';
import { injected } from 'src/configs/Connect/Configs.wallets';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import RPC from 'src/configs/Connect/Configs.rpc';

export interface IWallet {
    name: string;
    icon: string;
    connector?: any;
    href?: any;
}
export interface INetWork {
    name: string;
    icon: string;
    iconDisabled: string;
    chainNumb?: any;
    wallets: IWallet[];
}
export const networkList: INetWork[] = [
    {
        name: 'Ethereum',
        icon: 'ethereum.svg',
        iconDisabled: 'ethereum-disable.svg',
        chainNumb: ChainId.MAINNET,
        wallets: [
            {
                connector: injected,
                name: 'Meta Mask',
                icon: 'metamask.svg',
            },
            {
                connector: new WalletConnectConnector({
                    rpc: RPC,
                    bridge: 'https://bridge.walletconnect.org',
                    qrcode: true,
                    supportedChainIds: [
                        1, // mainnet
                        3, // ropsten
                        4, // rinkeby
                        5, // goreli
                        42, // kovan
                        250, // fantom
                        4002, // fantom testnet
                        137, // matic
                        80001, // matic testnet
                        100, // xdaiW
                        56, // binance smart chain
                        97, // binance smart chain testnet
                        1287, // moonbase
                        43114, // avalanche
                        43113, // fuji
                        128, // heco
                        256, // heco testnet
                        1666600000, // harmony
                        1666700000, // harmony testnet
                        66, // okex testnet
                        65, // okex testnet
                        42161, // arbitrum
                        42220, // celo
                        11297108109, // palm
                        1285, // moonriver
                    ],
                    // pollingInterval: 15000,
                }),
                name: 'WalletConnect',
                icon: 'walletconnect.svg',
            },
            {
                connector: async () => {
                    const { WalletLinkConnector } = await import('@web3-react/walletlink-connector');
                    return new WalletLinkConnector({
                        url: RPC[ChainId.MAINNET],
                        appName: 'SushiSwap',
                        appLogoUrl: 'https://raw.githubusercontent.com/sushiswap/art/master/sushi/logo-256x256.png',
                    });
                },
                name: 'Coinbase Wallet',
                icon: 'coinbase.svg',
            },
        ],
    },
    {
        name: 'Binance',
        icon: 'bsc.svg',
        chainNumb: ChainId.BSC,
        iconDisabled: 'bsc-disable.svg',
        wallets: [
            {
                connector: injected,
                name: 'Meta Mask',
                icon: 'metamask.svg',
            },
            {
                connector: new BscConnector({ supportedChainIds: [56] }),
                name: 'Binance',
                icon: 'binance.svg',
            },
            {
                connector: new WalletConnectConnector({
                    rpc: RPC,
                    bridge: 'https://bridge.walletconnect.org',
                    qrcode: true,
                    supportedChainIds: [
                        1, // mainnet
                        3, // ropsten
                        4, // rinkeby
                        5, // goreli
                        42, // kovan
                        250, // fantom
                        4002, // fantom testnet
                        137, // matic
                        80001, // matic testnet
                        100, // xdaiW
                        56, // binance smart chain
                        97, // binance smart chain testnet
                        1287, // moonbase
                        43114, // avalanche
                        43113, // fuji
                        128, // heco
                        256, // heco testnet
                        1666600000, // harmony
                        1666700000, // harmony testnet
                        66, // okex testnet
                        65, // okex testnet
                        42161, // arbitrum
                        42220, // celo
                        11297108109, // palm
                        1285, // moonriver
                    ],
                    // pollingInterval: 15000,
                }),
                name: 'WalletConnect',
                icon: 'walletconnect.svg',
            },
            {
                connector: async () => {
                    const { WalletLinkConnector } = await import('@web3-react/walletlink-connector');
                    return new WalletLinkConnector({
                        url: RPC[ChainId.MAINNET],
                        appName: 'SushiSwap',
                        appLogoUrl: 'https://raw.githubusercontent.com/sushiswap/art/master/sushi/logo-256x256.png',
                    });
                },
                name: 'Coinbase Wallet',
                icon: 'coinbase.svg',
            },
        ],
    },
    // {
    //     name: 'Binance Testnet',
    //     icon: 'bsc.svg',
    //     chainNumb: ChainId.BSC_TESTNET,
    //     iconDisabled: 'bsc-disable.svg',
    //     wallets: [
    //         {
    //             connector: injected,
    //             name: 'Meta Mask',
    //             icon: 'metamask.svg',
    //         },
    //     ],
    // },
    {
        name: 'Polygon',
        icon: 'polygon.svg',
        chainNumb: ChainId.MATIC,
        iconDisabled: 'polygon-disable.svg',
        wallets: [
            {
                connector: injected,
                name: 'Meta Mask',
                icon: 'metamask.svg',
            },
            {
                connector: new WalletConnectConnector({
                    rpc: RPC,
                    bridge: 'https://bridge.walletconnect.org',
                    qrcode: true,
                    supportedChainIds: [
                        1, // mainnet
                        3, // ropsten
                        4, // rinkeby
                        5, // goreli
                        42, // kovan
                        250, // fantom
                        4002, // fantom testnet
                        137, // matic
                        80001, // matic testnet
                        100, // xdaiW
                        56, // binance smart chain
                        97, // binance smart chain testnet
                        1287, // moonbase
                        43114, // avalanche
                        43113, // fuji
                        128, // heco
                        256, // heco testnet
                        1666600000, // harmony
                        1666700000, // harmony testnet
                        66, // okex testnet
                        65, // okex testnet
                        42161, // arbitrum
                        42220, // celo
                        11297108109, // palm
                        1285, // moonriver
                    ],
                    // pollingInterval: 15000,
                }),
                name: 'WalletConnect',
                icon: 'walletconnect.svg',
            },
            {
                connector: async () => {
                    const { WalletLinkConnector } = await import('@web3-react/walletlink-connector');
                    return new WalletLinkConnector({
                        url: RPC[ChainId.MAINNET],
                        appName: 'SushiSwap',
                        appLogoUrl: 'https://raw.githubusercontent.com/sushiswap/art/master/sushi/logo-256x256.png',
                    });
                },
                name: 'Coinbase Wallet',
                icon: 'coinbase.svg',
            },
        ],
    },
    {
        name: 'Solana',
        icon: 'solana.svg',
        iconDisabled: 'solana-disable.svg',
        chainNumb: 'solana',
        wallets: [
            {
                name: 'Phantom',
                icon: 'phantom.svg',
            },
        ],
    },
];
