import {
    apiGetAccessToken,
    apiGetNonceWithAddress,
    getSignature,
    apiGetNonceByRegister,
} from 'src/redux/account/Account.service';
import { IRootState } from 'src/redux/interface';
import uniqBy from 'lodash/uniqBy';
import { addToast } from 'src/components/Toast/redux/Toast.service';
import { TOAST_TYPES } from 'src/components/Toast/redux/Toast.action';
import useActiveWeb3React from 'src/hooks/useActiveWeb3React';
import { remove } from 'lodash';
import { ACTION_TYPES, AccountState, IToken } from './Account.type';
import { accessTokenSelector, addressSelector, tokenSelector } from './Account.selector';

export const actionUpdateAccount = (payload: AccountState) => ({
    type: ACTION_TYPES.ACTION_UPDATE_ACCOUNT,
    payload,
});

export const actionUpdateAccessToken = (payload: IToken[]) => ({
    type: ACTION_TYPES.ACTION_UPDATE_ACCESS_TOKEN,
    payload,
});

export const actionSignIn =
    (account: string, chainId: string | number, library: any) => async (dispatch: any, getState: () => IRootState) => {
        try {
            const state = getState();
            const checkToken = accessTokenSelector(state);
            if (checkToken) return;
            let tokens: IToken[] | [] = tokenSelector(state) || [];
            if (account) {
                const res: any = await apiGetNonceWithAddress(account);
                const isRegister = res.registered;
                let nonce = isRegister ? (res.nonce as any) : undefined;
                if (!isRegister) {
                    const resRegister = await apiGetNonceByRegister(account, chainId);
                    nonce = (resRegister as any).nonce;
                }
                const signature: string = await getSignature(account, nonce, library);
                const { token: accessToken } = await apiGetAccessToken(account, signature);
                const newToken: IToken = {
                    address: account.toLowerCase(),
                    token: accessToken,
                    nonce,
                };
                tokens?.unshift(newToken);
                tokens = uniqBy(tokens, (item) => item.address);
                dispatch(actionUpdateAccessToken(tokens));
            } else {
                dispatch(
                    addToast({
                        type: TOAST_TYPES.ERROR,
                        title: 'OOPS!',
                        description: 'Please connect your wallet',
                    }),
                );
            }
        } catch (error: any) {
            dispatch(
                addToast({
                    type: TOAST_TYPES.ERROR,
                    title: 'OOPS!',
                    description: error.message || error,
                }),
            );
        }
    };

export const onCheckAuthorize = (messageResponse: string) => (dispatch: any, getState: () => IRootState) => {
    if (messageResponse === 'jwt expired') {
        const state = getState();
        const { token } = state.account;
        const newToken = remove(token || [], (o) => {
            return o.address !== state.account.address?.toLowerCase();
        });
        dispatch(actionUpdateAccessToken(newToken || []));
        dispatch(
            addToast({
                type: TOAST_TYPES.ERROR,
                title: 'OOPS!',
                description: 'Expried. Please try sign again.',
            }),
        );
    }
};
