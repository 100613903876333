import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useWeb3React } from '@web3-react/core';
import { injected } from 'src/configs/Connect/Configs.wallets';

export default function useEagerConnect() {
    const { activate, active } = useWeb3React();
    const isConnector = localStorage.getItem('connector');
    const [tried, setTried] = useState(false);

    useEffect(() => {
        injected.isAuthorized().then((isAuthorized) => {
            if (isAuthorized && isConnector === 'injected') {
                activate(injected, undefined, true).catch((error) => {
                    setTried(true);
                    if (error?.name === 'UnsupportedChainIdError') {
                        // addChainRequest();
                    }
                });
            } else if (isMobile && window.ethereum) {
                activate(injected, undefined, true).catch(() => {
                    setTried(true);
                });
            } else {
                setTried(true);
            }
        });
    }, [activate, isConnector, active]); // intentionally only running on mount (make sure it's only mounted once :))
    useEffect(() => {
        if (active) {
            setTried(true);
        }
    }, [active]);

    return tried;
}
